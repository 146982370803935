/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */
export const MAPS_API = "AIzaSyDqm62_JJvnvLK6ob9UKY1oQ9LTx0ul_VA";
export const SET_SELECTED_LAUNDRY = "SET_SELECTED_LAUNDRY";

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

/* CONFIGURATION */
export const CONFIGURATION_FORM_TOOGLE_LOADING = "CONFIGURATION_FORM_TOOGLE_LOADING";
export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USER_FORM = "OPEN_USER_FORM";
export const CLOSE_USER_FORM = "CLOSE_USER_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* EQUIPMENT */
export const SET_EQUIPMENT_LIST = "SET_EQUIPMENT_LIST";
export const ADD_NEW_EQUIPMENT = "ADD_NEW_EQUIPMENT";
export const UPDATE_EQUIPMENT = "UPDATE_EQUIPMENT";
export const DELETE_EQUIPMENT = "DELETE_EQUIPMENT";
export const OPEN_EQUIPMENT_FORM = "OPEN_EQUIPMENT_FORM";
export const CLOSE_EQUIPMENT_FORM = "CLOSE_EQUIPMENT_FORM";
export const EDIT_SELECTED_EQUIPMENT = "EDIT_SELECTED_EQUIPMENT";
export const EQUIPMENT_FORM_TOOGLE_LOADING = "EQUIPMENT_FORM_TOOGLE_LOADING";
/* <---- EQUIPMENT ----> */

/* RESOURCE */
export const SET_RESOURCE_LIST = "SET_RESOURCE_LIST";
export const ADD_NEW_RESOURCE = "ADD_NEW_RESOURCE";
export const UPDATE_RESOURCE = "UPDATE_RESOURCE";
export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const OPEN_RESOURCE_FORM = "OPEN_RESOURCE_FORM";
export const CLOSE_RESOURCE_FORM = "CLOSE_RESOURCE_FORM";
export const EDIT_SELECTED_RESOURCE = "EDIT_SELECTED_RESOURCE";
export const RESOURCE_FORM_TOOGLE_LOADING = "RESOURCE_FORM_TOOGLE_LOADING";
/* <---- RESOURCE ----> */

/* LAUNDRY */
export const SET_LAUNDRY_LIST = "SET_LAUNDRY_LIST";
export const ADD_NEW_LAUNDRY = "ADD_NEW_LAUNDRY";
export const UPDATE_LAUNDRY = "UPDATE_LAUNDRY";
export const DELETE_LAUNDRY = "DELETE_LAUNDRY";
export const OPEN_LAUNDRY_FORM = "OPEN_LAUNDRY_FORM";
export const CLOSE_LAUNDRY_FORM = "CLOSE_LAUNDRY_FORM";
export const EDIT_SELECTED_LAUNDRY = "EDIT_SELECTED_LAUNDRY";
export const LAUNDRY_FORM_TOOGLE_LOADING = "LAUNDRY_FORM_TOOGLE_LOADING";
/* <---- LAUNDRY ----> */

/* CUSTOMER */
export const SET_CUSTOMER_LIST = "SET_CUSTOMER_LIST";
export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const OPEN_CUSTOMER_FORM = "OPEN_CUSTOMER_FORM";
export const CLOSE_CUSTOMER_FORM = "CLOSE_CUSTOMER_FORM";
export const EDIT_SELECTED_CUSTOMER = "EDIT_SELECTED_CUSTOMER";
export const CUSTOMER_FORM_TOOGLE_LOADING = "CUSTOMER_FORM_TOOGLE_LOADING";
/* <---- CUSTOMER ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */

/* ROLE */
export const SET_ROLE_LIST = "SET_ROLE_LIST";
export const ADD_NEW_ROLE = "ADD_NEW_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const OPEN_ROLE_FORM = "OPEN_ROLE_FORM";
export const CLOSE_ROLE_FORM = "CLOSE_ROLE_FORM";
export const EDIT_SELECTED_ROLE = "EDIT_SELECTED_ROLE";
export const ROLE_FORM_TOOGLE_LOADING = "ROLE_FORM_TOOGLE_LOADING";
/* <---- ROLE ----> */

/* HOLIDAY */
export const SET_HOLIDAY_LIST = "SET_HOLIDAY_LIST";
export const ADD_NEW_HOLIDAY = "ADD_NEW_HOLIDAY";
export const UPDATE_HOLIDAY = "UPDATE_HOLIDAY";
export const DELETE_HOLIDAY = "DELETE_HOLIDAY";
export const OPEN_HOLIDAY_FORM = "OPEN_HOLIDAY_FORM";
export const CLOSE_HOLIDAY_FORM = "CLOSE_HOLIDAY_FORM";
export const EDIT_SELECTED_HOLIDAY = "EDIT_SELECTED_HOLIDAY";
export const HOLIDAY_FORM_TOOGLE_LOADING = "HOLIDAY_FORM_TOOGLE_LOADING";
/* <---- HOLIDAY ----> */

/* PETITION */
export const SET_PETITION_LIST = "SET_PETITION_LIST";
export const ADD_NEW_PETITION = "ADD_NEW_PETITION";
export const UPDATE_PETITION = "UPDATE_PETITION";
export const DELETE_PETITION = "DELETE_PETITION";
export const OPEN_PETITION_FORM = "OPEN_PETITION_FORM";
export const CLOSE_PETITION_FORM = "CLOSE_PETITION_FORM";
export const EDIT_SELECTED_PETITION = "EDIT_SELECTED_PETITION";
export const PETITION_FORM_TOOGLE_LOADING = "PETITION_FORM_TOOGLE_LOADING";
/* <---- PETITION ----> */

/* SINGUP */
export const SET_SINGUP_LIST = "SET_SINGUP_LIST";
export const ADD_NEW_SINGUP = "ADD_NEW_SINGUP";
export const UPDATE_SINGUP = "UPDATE_SINGUP";
export const DELETE_SINGUP = "DELETE_SINGUP";
export const OPEN_SINGUP_FORM = "OPEN_SINGUP_FORM";
export const CLOSE_SINGUP_FORM = "CLOSE_SINGUP_FORM";
export const EDIT_SELECTED_SINGUP = "EDIT_SELECTED_SINGUP";
export const SINGUP_FORM_TOOGLE_LOADING = "SINGUP_FORM_TOOGLE_LOADING";
/* <---- SINGUP ----> */

/* PERMISION */
export const SET_PERMISION_LIST = "SET_PERMISION_LIST";
export const ADD_NEW_PERMISION = "ADD_NEW_PERMISION";
export const UPDATE_PERMISION = "UPDATE_PERMISION";
export const DELETE_PERMISION = "DELETE_PERMISION";
export const OPEN_PERMISION_FORM = "OPEN_PERMISION_FORM";
export const CLOSE_PERMISION_FORM = "CLOSE_PERMISION_FORM";
export const EDIT_SELECTED_PERMISION = "EDIT_SELECTED_PERMISION";
export const PERMISION_FORM_TOOGLE_LOADING = "PERMISION_FORM_TOOGLE_LOADING";
/* <---- PERMISION ----> */

/* ABSENCE */
export const SET_ABSENCE_LIST = "SET_ABSENCE_LIST";
export const ADD_NEW_ABSENCE = "ADD_NEW_ABSENCE";
export const UPDATE_ABSENCE = "UPDATE_ABSENCE";
export const DELETE_ABSENCE = "DELETE_ABSENCE";
export const OPEN_ABSENCE_FORM = "OPEN_ABSENCE_FORM";
export const CLOSE_ABSENCE_FORM = "CLOSE_ABSENCE_FORM";
export const EDIT_SELECTED_ABSENCE = "EDIT_SELECTED_ABSENCE";
export const ABSENCE_FORM_TOOGLE_LOADING = "ABSENCE_FORM_TOOGLE_LOADING";
/* <---- ABSENCE ----> */
